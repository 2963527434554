<template>
  <v-switch
      class="mt-1"
      v-model="localModel"
      :label="label"
      :rules="requiredRules"
      :required="required"
      inset
      color="success"
      :messages="message"
      @change="$emit('change', $event)"
  >
  </v-switch>

</template>

<script>

export default {
  name: "TestingSwitch",

  props: {
    value: {
      type: Boolean
    },
    required: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: function () {
        return this.$t('commons.fields.testing.label')
      }
    },
    hasMessage: {
      type: Boolean,
      default: true
    },
    requiredRules: Array
  },

  data() {
    return {
      options: []
    }
  },

  computed: {
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        console.log('emit update value ', value)
        this.$emit('update:value', value)
      }
    },
    message() {
      return (this.hasMessage) ? this.$t('commons.fields.testing.help_text') : ''
    }
  }
}
</script>

<style scoped>

</style>