<template>

  <v-select
      v-model="localModel"
      :label="$t('commons.fields.country')"
      :items="countries"
      item-text="name"
      item-value="value"
      :rules="requiredRules"
      :required="required"
      outlined
      dense
      @change="$emit('change', $event)"
      :clearable="clearable"
      :disabled="disabled"
  ></v-select>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "Countries",

    props: {
      country: String,
      clearable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array
    },

    computed: {
      ...mapGetters({
        countries: 'consts/getCountries'
      }),

      localModel: {
        get() {
          return this.country
        },
        set(country) {
          this.$emit('update:country', country)
        }
      }
    },
  }
</script>

<style scoped>

</style>