import i18n from "@/plugins/i18n";

const email_pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationRules = {
    data() {
        return {
            requiredRules: [
                v => (v !== '' || !!v) || i18n.t('commons.errors.validation.required')
            ],
            validEmailRules: [
                v => (email_pattern.test(v)) || i18n.t('commons.errors.validation.email')
            ]
        }
    }
}

