<template>
    <div class="d-flex justify-end">

        <v-btn color="warning darken-1"
               :disabled="isLoading"
               @click="$emit('close', $event)"
        >
            {{$t('commons.actions.cancel')}}
        </v-btn>

      <v-btn
          v-if="displayConfirmButton"
          :disabled="!valid || isLoading"
          :loading="isLoading"
          color="success"
          class="ml-4"
          @click="$emit('save', $event)"
      >
        {{goActionLabel}}
      </v-btn>

    </div>
</template>

<script>
    export default {
        name: "DialogButtons",

        props: {
            displayConfirmButton: {
                type: Boolean,
                default: true
            },
            valid: {
                type: Boolean,
                default: false
            },
          goActionLabel: {
              type: String,
              default: function () {
                return this.$t('commons.actions.save')
              }
          },
            isLoading: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style scoped>

</style>
