<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >

    <v-file-input
        :id="'upload-' + inputRef"
        v-if="!imgPreview"
        :ref="inputRef"
        :label="label"
        :disabled="disabled"
        v-model="file"
        accept="image/png, image/jpeg, image/jpg"
        prepend-icon=""
        show-size
        outlined
        dense
        @change="addPreview"
        style="display:none"
    ></v-file-input>

    <v-btn v-if="!imgPreview" color="blue-grey" class="black--text" @click.native="openFileDialog">
      {{ btnText }}
      <v-icon right dark>mdi-cloud-upload</v-icon>
    </v-btn>

    <v-img max-width="50%"
           contain
           v-if="imgPreview"
           :src="imgPreview"
           class="preview my-10"
    />

    <v-btn :loading="sending" color="primary" v-if="imgPreview" @click="startUpload" class="mr-5">
      {{$t('commons.actions.upload')}}
    </v-btn>
    <v-btn :disabled="sending" v-if="imgPreview" @click="clear">
      {{$t('commons.actions.cancel')}}
    </v-btn>



  </v-form>
</template>

<script>

  import {EventBus} from "@/event-bus";
  import apiClient from "@/plugins/apiClient";

  export default {
    name: "UploadFiles",

    props: {
      inputRef: {
        type: String,
        required: true
      },
      imgObj: {
        type: String,
        required: true
      },
      setCategory: {
        type: String,
      },
      label: {
        type: String,
        required: true
      },
      btnText: {
        type: String,
        default: 'Carica'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
    },

    data: (self) => ({
        file: null,
        imgPreview: '',
        sending: false,
        valid: false,
        requiredRules: [
          v => (v !== '' || !!v) || self.$t('commons.errors.validation.required'),
        ],
        category: '',
      }
    ),

    mounted() {
      let self = this

      if (this.setCategory) {
        this.category = this.setCategory
      }

      EventBus.$on('reset-file', function () {
        self.clear()
      })
    },

    methods: {
      openFileDialog() {
        document.getElementById('upload-' + this.inputRef).click();
      },
      addPreview(event)
      {
        let file = event
        if (file) {
          const reader = new FileReader
          reader.onload = e => {
            this.imgPreview = e.target.result
          }
          reader.readAsDataURL(file)
          this.$emit('input', file)
        }
      },

      startUpload() {
        const valid = this.$refs.form.validate()

        if (valid) {
          let formData = new FormData();
          formData.append("image", this.file);
          formData.append("category", this.category);
          this.sending = true

          apiClient.post('/images', formData, {
            onUploadProgress: progressEvent => {
              console.log('progressEvent ' , progressEvent)
                 console.log(progressEvent.loaded / progressEvent.total)
             }
          })
              .then(response => {
                this.clear()
                this.$emit('uploaded', response.data.data);
                this.$store.dispatch('image/fetchImages')
                this.sending = false
              })
              .catch(error => {
                 console.log('error ' , error)
                 console.log('error ' , error.data)
                this.sending = false
              })
        }
      },

      clear() {
        console.log('clear upload image preview')
        this.file = null
        this.imgPreview = ''
        console.log('this.file ' , this.file)
        console.log('this.imgPreview', this.imgPreview)
      }
    },
  }
</script>

<style scoped>
  .preview {
    border: 2px solid #dddddd;
    border-radius: 4px;
  }
</style>