<template>
  <v-expansion-panels :disabled="loading" class="mb-3">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <v-icon>{{ $t('icons.filters') }}</v-icon>
          {{ $t('commons.filters.title') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>

          <v-col md="3" sm="12" v-if="countryFilter">
            <countries
                :country.sync="country"
                :clearable="true"
                @change="emitCountryFilter"
            ></countries>
          </v-col>

          <v-col md="3" sm="12" v-if="freeSearchFilter">
            <v-text-field
                v-model="freeText"
                label="Cerca"
                outlined
                dense
                @input="emitFreeTextFilter"
                :clearable="true"
            ></v-text-field>
          </v-col>

          <v-col md="3" sm="12" v-if="appVersionFilter">
            <app-version-select
                :value.sync="appVersion"
                :label="$t('commons.fields.min_app_version')"
                @change="emitAppVersionFilter"
                :clearable="true"
            ></app-version-select>
          </v-col>

          <v-col md="3" sm="12" v-if="testingFilter">
            <testing-switch
                :value.sync="testing"
                @change="emitTestingFilter"
                :has-message="false"
            ></testing-switch>
          </v-col>

        </v-row>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import Countries from "@/components/form/Countries";
import AppVersionSelect from "@/components/form/AppVersionSelect";
import TestingSwitch from "@/components/form/TestingSwitch";

export default {
  name: "CountryFilter",

  components: {
    Countries,
    AppVersionSelect,
    TestingSwitch
  },

  props: {
    loading: {
      type: Boolean,
      required: true
    },
    countryFilter: {
      type: Boolean,
      default: false
    },
    freeSearchFilter: {
      type: Boolean,
      default: false
    },
    appVersionFilter: {
      type: Boolean,
      default: false
    },
    testingFilter: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      country: '',
      freeText: '',
      appVersion: '',
      testing: false
    }
  },

  methods: {
    emitCountryFilter() {
      // country
      this.$emit('update:country', this.country)
      this.$emit('change')
    },

    emitFreeTextFilter() {
      if (!this.freeText || this.freeText.length >= 3) {
        this.$emit('update:freeText', this.freeText)
        this.$emit('change')
      }
    },

    emitAppVersionFilter() {
      this.$emit('update:appVersion', this.appVersion)
      this.$emit('change')
    },

    emitTestingFilter() {
      this.$emit('update:testing', this.testing)
      this.$emit('change')
    },

  }
}
</script>

<style scoped>

</style>