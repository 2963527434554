<template>
  <v-expansion-panels :disabled="loading">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <v-icon>{{ $t('icons.filters') }}</v-icon>
          {{ $t('commons.filters.title') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col md="6" sm="12">
            <countries
                :country.sync="country"
                :clearable="true"
                @change="emitFilters"
            ></countries>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import Countries from "@/components/form/Countries";

export default {
  name: "CountryFilter",

  components: {
    Countries
  },

  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      country: ''
    }
  },

  methods: {
    emitFilters() {
      this.$emit('update:country', this.country)
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>