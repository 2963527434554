<template>
     <v-menu
        v-model="dPicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedDate"
                :label="label"
                readonly
                :required="required"
                :rules="requiredRules"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                :messages="inputMessage"
            ></v-text-field>
        </template>
        <v-date-picker
            :id="id"
            v-model="localModel"
            @input="dPicker = false"
            locale="it-it"
            first-day-of-week="1"
            :min="min"
            :max="max"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    import moment from "moment";

    export default {
        name: "Datepicker",

        props: {
          date: String,
          id: String,
          max: [String, Date],
          min: [String, Date],
          required: {
              type: Boolean,
              default: false
          },
          inputMessage: {
            type: String,
            default: ''
          },
          label: {
              type: String,
            required: true
          },
          requiredRules: Array,

        },

        data () {
            return {
                dPicker: false,
            }
        },

        computed: {
            localModel: {
                get() {
                    return this.date
                },
                set(value) {
                    this.$emit('update:date', value)
                }
            },
            formattedDate: {
              get() {
                return (this.date) ? moment(this.date, 'YYYY-MM-DD').format('DD-MM-YYYY') : this.$t('commons.filters.select_date')
              },
              set() {

              }
            }
        },
    }
</script>

<style scoped>

</style>
