<template>

    <v-select
        v-model="localModel"
        :label="label"
        :items="options"
        :rules="requiredRules"
        :required="required"
        :loading="fetching"
        :clearable="clearable"
        outlined
        dense
        @change="$emit('change', $event)"
    ></v-select>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AppVersionSelect",

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    requiredRules: Array
  },

  data() {
    return {
      options: []
    }
  },

  computed: {
    ...mapGetters({
      fetching: 'venchiOption/loadingState'
    }),
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        console.log('emit update value ', value)
        this.$emit('update:value', value)
      }
    },
  },

  mounted() {
    this.getOptions()
  },

  methods: {
    getOptions() {
      this.$store.dispatch('venchiOption/fetchOption', 'app-versions')
      .then(response => {
        this.options = response.data.split(',')
      })
    }
  }
}
</script>

<style scoped>

</style>