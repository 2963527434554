<template>
  <h3 class="text--primary border-bottom pb-2">{{ title }}</h3>
</template>

<script>
export default {
  name: "FormContentTitle",

  props: {
    title: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>