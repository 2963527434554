<template>
  <div>
    <v-btn
        color="primary"
        small
        class="mb-2"
        @click="openImageBrowser"
    > {{ (image && image.id) ? $t('commons.actions.change') :$t('images.buttons.set_' + btnLabel) }} </v-btn>

    <v-dialog
        v-model="dialog"
        max-width="70%"
        persistent
        overlay-opacity="0.8"
        :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-row>
        <v-col cols="12">
          <v-card>

            <v-container fluid>

              <v-tabs
                  fixed-tabs
                  v-model="tab"
                  hide-slider
                  @change="reset"
              >
                <v-tab>
                  {{ $t('images.browser.buttons.new') }}
                </v-tab>
                <v-tab>
                  {{ $t('images.browser.buttons.select') }}
                </v-tab>
              </v-tabs>

              <v-divider></v-divider>

              <v-tabs-items v-model="tab">

                <v-tab-item :transition="false" :reverse-transition="false">
                  <v-row>
                    <v-col class="text-center align-content-center">
                      <p class="py-3">{{ $t('commons.texts.select_image_from_pc')}}</p>
                      <upload-files
                          :input-ref="id"
                          :img-obj="imgObj"
                          :label="$t('images.categories.' + category)"
                          :set-category="category"
                          @uploaded="uploaded"
                      ></upload-files>

                    </v-col>
                  </v-row>

                </v-tab-item>

                <v-tab-item :transition="false" :reverse-transition="false">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col
                            v-for="image in images"
                            :key="image.id"
                            class="d-flex child-flex"
                            cols="3"
                        >
                          <v-card flat tile class="d-flex">
                            <v-img
                                :src="image.url"
                                class="bordered-image pointer"
                                contain
                                max-height="300"
                                min-height="300"
                                @click="setImage(image)"
                                :class="{'selected': (selected.id === image.id)}"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <div class="text-center py-3">
                        <v-pagination
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="getImages()"
                            :disabled="isLoading"
                        ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col class="text-center">
                      <v-btn v-if="selected"
                             class="mx-2"
                             @click="confirm"
                             color="success"
                      >
                        Conferma
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <v-btn color="blue darken-1"
                         text
                         class="mx-2"
                         @click="closeImageBrowser">
                    {{ $t('commons.actions.cancel') }}
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card>
        </v-col>
      </v-row>

    </v-dialog>
  </div>
</template>

<script>

  import {mapGetters} from "vuex";
  import UploadFiles from "@/components/form/UploadFiles";
  import {EventBus} from "@/event-bus";

  export default {
    name: "ImageBrowser",

    data() {
      return {
        dialog: false,
        tab: null,
        upload: true,
        selected: '',
        file: null
      }
    },

    props: {
      id: String,
      image: {},
      category: String,
      imgObj: String,
      btnLabel: String
    },

    components: {UploadFiles},

    mounted() {
      // this.$store.dispatch('image/fetchImages')
    },

    computed: {
      ...mapGetters({
        images: 'image/getImages',
        isLoading: 'image/loadingState',
        pagination: 'image/getPagination'
      }),
      localModel: {
        get() {
          return this.image
        },
        set(image) {
          this.$emit('update:image', image)
          this.closeImageBrowser()
        }
      }
    },

    methods: {
      openImageBrowser() {
        this.dialog = true
      },

      closeImageBrowser() {
        this.dialog = false
        this.reset()
      },

      uploaded(event) {
        this.localModel = event
      },

      getImages() {
        this.$store.dispatch('image/fetchImages')
      },

      setImage(image) {
        if (this.selected && image.id === this.selected.id) {
          this.selected = ''
          return
        }
        this.selected = image
      },

      confirm() {
        this.localModel = this.selected
        this.selected = ''
      },

      reset() {
        // this.upload= true
        this.selected= ''
        // this.file= null
        this.$store.dispatch('image/resetPagination')
        this.$store.dispatch('image/fetchImages')
        EventBus.$emit('reset-file')
      }
    }
  }
</script>

<style scoped>
.left-border {
  border-left: 1px solid #ddd;
}

.pointer {
  cursor: pointer;
}

div.bordered-image {
  border: 1px solid #ddd;
}
div.selected {
  border: 2px solid red !important;
}
</style>