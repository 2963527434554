<template>
  <v-autocomplete
      v-model="localModel"
      :items="missions"
      clearable
      
      item-value="id"
      :label="$t('missions.fields.parent')"
      v-on:change="$emit('change', $event)"
      :disabled="disabled"
      outlined
      dense
  >
   <template v-slot:item='{item}'><div><b class="me-2">#{{item.id}} </b> {{item.title}} </div></template>
   <template v-slot:selection='{item}'><div><b class="me-2">#{{item.id}} </b> {{item.title}} </div></template>
  </v-autocomplete>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "MissionParent",

  props: {
    mission: Number,
    disabled: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      missions: 'mission/getParentMissions',
    }),

    localModel: {
      get() {
        return this.mission
      },
      set(mission) {
        this.$emit('update:mission', mission)
      }
    }
  },
}
</script>

<style scoped>
.textminimal{
  font-size: 12px!important;
}
</style>