<template>
  <div>
    <template v-if="image && Object.prototype.hasOwnProperty.call(image, 'url')">
      <p>{{ label }}</p>
      <v-img
          :src="image.url"
          class="pointer mb-5 pa-10 border-all"
          min-height="150"
          :height="height"
          :max-width="maxWidth"
          contain
          style="background-color: #eeeeee;"
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </template>
    <image-browser :id="id"
                   category="mission_image"
                   img-obj="image"
                   :btn-label="btnLabel"
                   :image.sync="localModel"
    ></image-browser>
    <v-input v-if="image"
             v-model="image.id"
             :rules="requiredRules"
             required
             class="text-center"
    />
  </div>
</template>

<script>

  import ImageBrowser from "@/components/images/ImageBrowser";

  export default {
    name: "ImageUpload",

    components: {ImageBrowser},

    props: {
      id: {
        type: String,
        required: true
      },
      image: {
        type: Object
      },
      label: {
        type: String,
        default: 'Immagine'
      },
      btnLabel: {
        type: String,
        default: 'Immagine'
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array,
      height: {
        type: String,
        default: '300'
      },
      maxWidth: {
        type: String
      }
    },

    computed: {
      localModel: {
        get() {
          return this.image
        },
        set(image) {
          this.$emit('update:image', image)
        }
      }
    }
  }
</script>

<style scoped>

</style>