import { render, staticRenderFns } from "./ImageUpload.vue?vue&type=template&id=3d55bb52&scoped=true&"
import script from "./ImageUpload.vue?vue&type=script&lang=js&"
export * from "./ImageUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d55bb52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VInput } from 'vuetify/lib/components/VInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VImg,VInput,VProgressCircular,VRow})
